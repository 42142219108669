import { Environment } from './ienvironment';

export const environment: Environment = {
  production: false,
  appUrl: 'https://app.dev.vinlivt.de',
  apiEndpoint: 'https://api.dev.vinlivt.de',
  webSocketEndpoint: 'wss://websocket.dev.vinlivt.de',
  keycloakRealm: 'b2b',
  maxFileUploadSize: 10000000,
  location: 'dev',
  fondsFinanzCallbackUid: '5664a6b0-d4df-4bd9-86ff-29e736913f37',
  fondsFinanzClientId: '2b89c919-31ed-47e1-b04d-014837ce7ddc',
  service: 'advisor-app',
  env: 'dev',
  version: '1.0.0',
};
