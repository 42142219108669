export class WealthCard {
  category: string;
  assets: {
    id: number;
    imageUrl: string;
    name: string;
    price: number;
    subcategoryName: string;
  }
  totalPrice: number
}

export class BaseAsset {
  id: number;
  title: string;
  value: number;
  purchaseInfo: PurchaseOption;
  note?: string;
  imageUrl?: string;
}

// real Estate Model

export class MainInformation {
  streetNameAndNumber: string;
  zipCode: string;
  cityName: string;
  countryName: string;
}

export class Specifications {
  propertySize: number;
  livingSpace: number;
  constructionYear: number;
}

export class RealEstate extends BaseAsset {
  mainInformation: MainInformation;
  specifications: Specifications;
}


// mobility Model

export class MobilityBaseInformation {
  manufacturer: string;
  model: string;
  firstRegistration: string;
  mileage: number;
  licensePlate: string;
}

export class BoatInformation {
  shipBuilder: string;
  constructionYear: string;
}

export class OtherMobilityInformation {
  description: string;
}

export class MobilityAsset extends BaseAsset {
  subCategory: 'car' | 'motorcycle' | 'boat' | 'other';
  carMotorcycleInfo?: MobilityBaseInformation;
  boatInfo?: BoatInformation;
  otherInfo?: OtherMobilityInformation;
}

// Precious Metal
export class PreciousMetalAsset extends BaseAsset {
  metalType: string;
  quantity: number;
}

// Jewelry
export class JewelryAsset extends BaseAsset {
}

// Art
export class ArtAsset extends BaseAsset {
}

// Other
export class OtherAsset extends BaseAsset {
}

export class CashOption {
  purchaseType: 'Cash';
  purchasePrice: string;
  dateOfPurchase: string;
  currentMarketValue: number;
}

export class LeasingOption {
  purchaseType: 'Leasing';
  leasingStartDate: string;
  leasingEndDate: string;
  leasingInstalment: string;
  currentMarketValue: number;
}

export class FinancingOption {
  purchaseType: 'Financing';
  financingInstalment: string;
  financingStartDate: string;
  financingEndDate: string;
  currentMarketValue: number;
}

export type PurchaseOption = CashOption | LeasingOption | FinancingOption;

export class PurchaseDetails {
  purchaseInfo: PurchaseOption;
  loading?: boolean;

  isPurchaseTypeCash(purchase: PurchaseOption): purchase is CashOption {
    return purchase.purchaseType === 'Cash';
  }

  isPurchaseTypeLeasing(purchase: PurchaseOption): purchase is LeasingOption {
    return purchase.purchaseType === 'Leasing';
  }

  isPurchaseTypeFinancing(purchase: PurchaseOption): purchase is FinancingOption {
    return purchase.purchaseType === 'Financing';
  }
}

export class CarouselImage {
  attachmentUrl: string;
  id: string;
  altText?: string;
}


