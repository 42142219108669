import { Component, OnDestroy, OnInit } from '@angular/core';

import { NavigationStart, Router } from '@angular/router';

import { SeoService } from './shared/service';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private seoService: SeoService,
  ) {
    datadogRum.init({
      applicationId: '0acaf000-f9e6-4eca-ae22-adcbb3465b6e',
      clientToken: 'pubee93d2b12b4d6fc3df39f23e37bb24bc',
      site: 'datadoghq.eu',
      service: 'advisor-app',
      env: environment.env,
      version: environment.version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
  }

  public ngOnInit(): void {
    this.router.events.subscribe((event): void => {
      if (event instanceof NavigationStart) {
        if (!!event.url && event.url.match(/^\/#/)) {
          this.router.navigate([event.url.replace('/#', '')]);
        }
      }
    });

    this.seoService.generateTags({
      title: 'Vinlivt Business | Mein Vinlivt Business',
    });
  }

  public ngOnDestroy(): void {
    datadogRum.stopSession();
  }
}
