export enum Roles {
  COMPANY_ADMIN = 'company-admin',
  ANALYSIS = 'analysis',
  CHAT = 'chat',
  GROUP_CHAT = 'group-chat',
  TASK = 'task',
  MEDIA_STUDIO = 'media-studio',
  MANDATE = 'mandate',
  CAMPAIGN = 'campaign',
  POOL_CONNECTION = 'pool-connection',
  WHITE_LABEL = 'white-label',
  DATA_IMPORTER = 'data-importer',
  IN_SIGN = 'in-sign',
  ZAPIER = 'zapier',
  NATIVE_APP = 'native-app',
  FINANCE_REPORT = 'finance-report',
  AI = 'ai',
  SMS = 'sms',
  REPRESENTATIVE = 'representative',
}
