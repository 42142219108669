import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import {
  AnalysisReport,
  AnalysisReportRequest,
  BankAccount,
  BankAccountTransactionsRequest,
  BankAccountTransactionsResponse,
  Transaction,
} from '../model';

@Injectable({
  providedIn: 'root',
})
export class OpenBankingService {
  constructor(private http: HttpClient) {}

  public getUserAccounts(
    uuid: string,
    include_hidden: boolean = false,
    sharedOnly: boolean = true,
  ): Observable<BankAccount[]> {
    return this.http.get<BankAccount[]>(
      `${environment.apiEndpoint}/open-banking/account/${uuid}?include_hidden=${include_hidden}&sharedOnly=${sharedOnly}`,
    );
  }

  public createReport(uuid: string, reportRequest: AnalysisReportRequest): Observable<AnalysisReport> {
    return this.http.post<AnalysisReport>(
      `${environment.apiEndpoint}/open-banking/analysis/report/${uuid}`,
      reportRequest,
    );
  }

  public getBankAccountTransactions(
    uuid: string,
    bankAccountTransactionsRequest?: BankAccountTransactionsRequest,
  ): Observable<BankAccountTransactionsResponse> {
    let params: HttpParams = new HttpParams();

    if (bankAccountTransactionsRequest) {
      Object.keys(bankAccountTransactionsRequest).forEach((key: string): void => {
        const value = (bankAccountTransactionsRequest as any)[key];
        if (value !== undefined && value !== null && value !== '') {
          if (Array.isArray(value)) {
            value.forEach((arrayValue): void => {
              params = params.append(key, arrayValue);
            });
          } else {
            params = params.set(key, value);
          }
        }
      });
    }

    return this.http.get<BankAccountTransactionsResponse>(
      `${environment.apiEndpoint}/open-banking/transaction/${uuid}`,
      { params },
    );
  }
}
