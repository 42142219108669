import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, Observable, throwError} from "rxjs";
import {environment} from "../../../environments/environment";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class WealthService {
  private apiEndpoint = `${environment.apiEndpoint}/wealth`;

  constructor(private http: HttpClient) {
  }

  public getAggregatedAssets(uuid): Observable<any> {

    return this.http.get<any>(`${this.apiEndpoint}/aggregated-assets/${uuid}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        console.error('Error fetching aggregated assets:', error);
        return throwError(() => new Error('Failed to load aggregated assets'));
      })
    );
  }

  public getAssetDetailsById(category: string, assetId: string, uuid: string): Observable<any> {
    return this.http.get<any>(`${this.apiEndpoint}/${category}/${assetId}/user/${uuid}`).pipe(
      map((res: any) => res),
      catchError((error) => {
        console.error('Error fetching asset details:', error);
        return throwError(() => new Error('Failed to load asset details'));
      })
    );
  }
}
