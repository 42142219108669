import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActivityBadgeService {
  public showBadge: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {}

  public getUpdatedBadge(): Observable<boolean> {
    return this.showBadge.asObservable();
  }

  public setUpdatedBadge(value: boolean): void {
    this.showBadge.next(value);
  }
}
