import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { InviteEventData } from '../model';

@Injectable({
  providedIn: 'root',
})
export class ClientDetailsService {
  currentClient;

  constructor(private http: HttpClient) {}

  public get(clientId: string): Observable<any> {
    return this.http.get<any>(`${environment.apiEndpoint}/b2b/clients/detail/${clientId}`);
  }

  public changeEmailAndInvite(inviteEventData: InviteEventData): Observable<any> {
    return this.http.post<any>(`${environment.apiEndpoint}/b2b/clients/detail/changeemail`, inviteEventData);
  }
}
