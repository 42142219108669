import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

// directives
import {
  AutofocusDirective,
  DragAndDropDirective,
  PasswordValidatorDirective,
  TextareaAutosizeDirective,
  GroupCheckDirective,
  RoleCheckDirective,
  DelaySkeletonDirective,
} from './directive';

// guards
import { AuthInterceptor } from './interceptor/auth.interceptor';

// pipes
import {
  CalendarEventPipe,
  LastEightPipe,
  EnumArrayConvertedToStringPipe,
  EnumConvertedToArrayPipe,
  EnumToArrayPipe,
  GroupByPipe,
  SafePipe,
  SafeSrcPipe,
} from './pipe';

// services
import { SeoService } from './service';
import { authServiceProvider } from './service';

@NgModule({
  declarations: [
    PasswordValidatorDirective,
    TextareaAutosizeDirective,
    AutofocusDirective,
    DragAndDropDirective,
    SafePipe,
    EnumToArrayPipe,
    EnumConvertedToArrayPipe,
    EnumArrayConvertedToStringPipe,
    GroupByPipe,
    SafeSrcPipe,
    CalendarEventPipe,
    LastEightPipe,
    GroupCheckDirective,
    RoleCheckDirective,
    DelaySkeletonDirective,
  ],
  imports: [],
  exports: [
    PasswordValidatorDirective,
    TextareaAutosizeDirective,
    AutofocusDirective,
    DragAndDropDirective,
    SafePipe,
    EnumToArrayPipe,
    EnumConvertedToArrayPipe,
    EnumArrayConvertedToStringPipe,
    CalendarEventPipe,
    LastEightPipe,
    GroupByPipe,
    SafeSrcPipe,
    GroupCheckDirective,
    RoleCheckDirective,
    DelaySkeletonDirective,
  ],
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        SeoService,
        authServiceProvider,
        {
          provide: HTTP_INTERCEPTORS,
          multi: true,
          useClass: AuthInterceptor,
        },
      ],
    };
  }
}
