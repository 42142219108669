import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FondsfinanzLoginResponse } from '../model/pool/fondsfinanz-login-response';
import { PoolStatusResponse } from '../model/pool/pool-status-response';

@Injectable({
  providedIn: 'root',
})
export class BrokerPoolService {
  constructor(private http: HttpClient) {}

  public validateToken(userId: string): Observable<any> {
    return this.http.get<any>(`${environment.apiEndpoint}/brokerpool/fondsfinanz/token/validate/${userId}`);
  }

  public revokeToken(userId: string): Observable<any> {
    return this.http.get<any>(`${environment.apiEndpoint}/brokerpool/fondsfinanz/token/revoke/${userId}`);
  }

  public requestLoginUrl(userId: string): Observable<FondsfinanzLoginResponse> {
    return this.http.get<FondsfinanzLoginResponse>(`${environment.apiEndpoint}/brokerpool/fondsfinanz/login/${userId}`);
  }

  public countHouseholds(userId: string): Observable<any> {
    return this.http.get<any>(`${environment.apiEndpoint}/brokerpool/fondsfinanz/households/count/${userId}`);
  }

  public synchroniseData(): Observable<any> {
    return this.http.get<any>(`${environment.apiEndpoint}/b2b/fondsfinanz/import`);
  }

  public checkImportStatus(): Observable<PoolStatusResponse> {
    return this.http.get<any>(`${environment.apiEndpoint}/b2b/fondsfinanz/status`);
  }
}
