import { Component, EventEmitter, HostListener, Inject, isDevMode, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { WebSocketService } from '@vinlivt/websocket';

import { environment } from '../../../environments/environment';

import { AdvisorInfoUpdateService } from '../../shared/global-store';
import { AdvisorsService, AUTH_SERVICE_TOKEN, AuthService, CompanyService, VinhubService } from '../../shared/service';

import { Advisor, Company, VinhubMessage } from '../../shared/model';

import { Groups } from '../../shared/enum/groups.enum';
import { Roles } from '../../shared/enum/roles.enum';

import WithSubscription from '../../shared/utils/WithSubscription';
import { ToastrService } from 'ngx-toastr';
import { ActivityBadgeService } from '../../shared/global-store/activity-badge.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './app-sidebar.component.html',
  styleUrls: ['./app-sidebar.component.scss'],
})
export class SidebarComponent extends WithSubscription(Object) implements OnInit {
  protected devMode: boolean = false;
  protected innerHeight: number = innerHeight;
  protected innerWidth: number = innerWidth;

  protected company: Company = new Company();
  private advisor: Advisor = new Advisor();

  protected totalUnreadMessages: number = 0;
  protected showActivityBadge: boolean = false;

  constructor(
    @Inject(AUTH_SERVICE_TOKEN) protected readonly authService: AuthService,
    private readonly advisorsService: AdvisorsService,
    private readonly companyService: CompanyService,
    private readonly toastrService: ToastrService,
    private readonly advisorInfoUpdateService: AdvisorInfoUpdateService,
    private readonly activityBadgeService: ActivityBadgeService,
    private readonly webSocketService: WebSocketService,
    private readonly vinhubService: VinhubService,
    private readonly router: Router,
  ) {
    super();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.windowSize();
  }

  public ngOnInit(): void {
    this.loadAdvisorAccount();
    this.countUnreadContracts();
    this.listenForEvents();
    this.getServices();
  }

  private windowSize(): void {
    this.innerHeight = window.innerHeight;
    this.innerWidth = window.innerWidth;
  }

  private getServices(): void {
    this.listenToSubject(this.advisorsService.profileDataChangedSubject, (): void => {
      // load settings after 2 secs.
      setTimeout((): void => {
        this.loadAdvisorAccount();
      }, 1000);
    });

    this.listenToSubject(this.companyService.companyDataChangedSubject, (): void => {
      // load settings after 2 secs.
      setTimeout((): void => {
        this.loadAdvisorAccount();
      }, 1000);
    });

    this.advisorInfoUpdateService.getUpdatedAdvisor().subscribe((value: Advisor): void => {
      this.loadAdvisorAccount();
    });

    this.advisorInfoUpdateService.getUpdatedCompanyLogo().subscribe((value: Company): void => {
      this.company = value;
    });

    this.activityBadgeService.getUpdatedBadge().subscribe((value: boolean): void => {
      this.showActivityBadge = value;
    });
  }

  private messageArrivedPlaySound(): void {
    const audio: HTMLAudioElement = new Audio();
    audio.src = 'assets/sounds/message-ring.mp3';
    audio.load();
    audio.play();
  }

  private loadAdvisorAccount(): void {
    this.advisorsService.getAdvisorProfile().subscribe((data: Advisor): void => {
      this.advisor = data;
      this.getInfoAfterGettingsAdvisor();
    });
  }

  protected companyLogoUrl(): void {
    if (this.company?.id) {
      this.companyService.companyLogoUrl(this.company?.id).subscribe({
        next: (url: string): string => {
          return url;
        },
        error: (error: string): void => {
          console.log(error);
        },
      });
    }
  }

  protected isMediaStudioActive(): boolean {
    return this.router.url.startsWith('/media-studio');
  }

  protected navigateToMediaStudio(): void {
    if (this.authService.hasRole(Roles.MEDIA_STUDIO)) {
      this.router.navigate(['/media-studio']);
    } else {
      this.router.navigate(['/profile/restricted-product/media-studio']);
    }
  }

  private getInfoAfterGettingsAdvisor(): void {
    this.company = this.advisor.company;

    // turn on mdia studio only for dev mode and vinlivt user account on prod
    if (!environment.production || (environment.production && this.company.id === 2)) {
      this.devMode = true;
    }
  }

  private listenForEvents(): void {
    this.listenToSubject(
      this.webSocketService.subscribe<number>('chat/changed-unread-count'),
      (messageCount: number): void => {
        this.totalUnreadMessages = messageCount;
      },
    );

    this.listenToSubject(
      this.webSocketService.subscribe<VinhubMessage>('chat/new-message'),
      (message: VinhubMessage): void => {
        if (message.authorId !== this.authService.userId) {
          this.totalUnreadMessages++;
          this.messageArrivedPlaySound();
        }
      },
    );

    this.listenToSubject(
      this.webSocketService.subscribe('activity/new-activity-log'),
      (data: { eventType: string }): void => {
        if (data?.eventType) {
          if (!this.router.url.includes('activity')) {
            this.showActivityBadge = true;
            this.activityBadgeService.setUpdatedBadge(true);
          }
        }
      },
    );

    this.listenToSubject(
      this.webSocketService.subscribe<VinhubMessage>('brokerpool/finished-synch'),
      (message: any): void => {
        this.toastrService.info('Pooldaten wurden erfolgreich synchronisiert.');
      },
    );
  }

  private countUnreadContracts(): void {
    this.vinhubService.checkUnreadMessages().subscribe({
      next: (data: number): void => {
        this.totalUnreadMessages = data;
      },
      error: (error): void => {
        console.log(error);
      },
    });

    // this.chatMessageService.countTotalUnreadMessages().subscribe((amount: number): void => {
    //   this.totalUnreadMessages = amount;
    // });
  }

  protected goToOtherExternalUrl(): void {
    window.open('https://1jd89nuf213.typeform.com/to/k6epXxWC', '_blank');
  }

  protected readonly Groups = Groups;
  protected readonly Roles = Roles;
  protected readonly isDevMode = isDevMode;
}
